// frontend/src/components/RawXmlModal.tsx

import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';

interface RawXmlModalProps {
  xmlContent: string;
  reportId?: string;
}

const RawXmlModal: React.FC<RawXmlModalProps> = ({ xmlContent, reportId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  // Gelişmiş XML formatlama fonksiyonu
  const formatXml = (xml: string): string => {
    if (!xml) return 'XML içeriği bulunamadı';

    try {
      const parser = new DOMParser();
      const doc = parser.parseFromString(xml, 'text/xml');
      
      // XML formatting error check
      const parserError = doc.querySelector('parsererror');
      if (parserError) {
        throw new Error('Invalid XML');
      }

      // Custom pretty print function
      const PADDING = ' '.repeat(2); // 2 spaces for indentation
      const reg = /(>)(<)(\/*)/g;
      
      let formatted = xml.replace(reg, '$1\r\n$2$3');
      let pad_level = 0;
      
      return formatted
        .split('\r\n')
        .map(node => {
          let indent = 0;
          if (node.match(/.+<\/\w[^>]*>$/)) {
            indent = 0;
          } else if (node.match(/^<\/\w/) && pad_level > 0) {
            pad_level -= 1;
          } else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
            indent = 1;
          } else {
            indent = 0;
          }
          
          pad_level += indent;
          return PADDING.repeat(pad_level - indent) + node;
        })
        .join('\r\n');
    } catch (err) {
      console.error('XML formatting error:', err);
      return 'XML format hatası: Geçersiz XML içeriği';
    }
  };

  // Kopyalama fonksiyonu ve animasyon
  const copyToClipboard = async () => {
    try {
      if (!xmlContent) throw new Error('Kopyalanacak içerik bulunamadı');
      await navigator.clipboard.writeText(xmlContent);
      setShowCopySuccess(true);
      setTimeout(() => setShowCopySuccess(false), 2000);
    } catch (err) {
      console.error('Copy failed:', err);
    }
  };

  const handleOpen = () => {
    if (!xmlContent) {
      console.warn('XML content is missing');
    }
    setIsOpen(true);
  };

  if (!isOpen) {
    return (
      <button
        onClick={handleOpen}
        className="px-3 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50 text-gray-700"
      >
        Raw XML
      </button>
    );
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg max-w-4xl w-full max-h-[80vh] m-4">
        {/* Modal Header */}
        <div className="flex items-center justify-between p-4 border-b">
          <div>
            <h3 className="text-lg font-semibold">Raw XML İçeriği</h3>
            {reportId && (
              <p className="text-sm text-gray-500 mt-1">Report ID: {reportId}</p>
            )}
          </div>
          <div className="flex gap-2 items-center">
            <button
              onClick={copyToClipboard}
              className="relative p-2 hover:bg-gray-50 rounded-md transition-colors duration-200 group"
              title="Kopyala"
              disabled={!xmlContent}
            >
              {showCopySuccess ? (
                <Check className="w-5 h-5 text-green-500 transition-transform duration-200 animate-scale" />
              ) : (
                <Copy className={`w-5 h-5 ${xmlContent ? 'text-gray-500 group-hover:text-gray-700' : 'text-gray-300'} transition-colors duration-200`} />
              )}
              {/* Copy success animation */}
              {showCopySuccess && (
                <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-black text-white text-xs py-1 px-2 rounded-md animate-fade-out">
                  Kopyalandı
                </span>
              )}
            </button>
            <button
              onClick={() => setIsOpen(false)}
              className="p-2 hover:bg-gray-100 rounded-md"
              title="Kapat"
            >
              ✕
            </button>
          </div>
        </div>
        
        {/* Modal Content */}
        <div className="overflow-auto p-4 max-h-[calc(80vh-8rem)]">
          {xmlContent ? (
            <pre className="p-4 bg-gray-50 rounded-lg text-sm font-mono whitespace-pre-wrap break-all">
              {formatXml(xmlContent)}
            </pre>
          ) : (
            <div className="text-center py-8 text-gray-500">
              XML içeriği bulunamadı
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RawXmlModal;