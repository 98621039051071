// frontend/src/components/DmarcReport.tsx

import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Card, CardHeader, CardContent } from './Card';
import { Report, DmarcRecord, CategoryType, RecordDisplay } from '../types';
import { getIpProvider } from '../utils/reportUtils';
import RawXmlModal from './RawXmlModal';

import { 
    AlertCircle,
    CheckCircle2, 
    XCircle,
    Shield, 
    AlertTriangle,
    Forward,
    Globe,
    Flag
} from 'lucide-react';

ChartJS.register(ArcElement, Tooltip, Legend);

interface DmarcReportProps {
    report: Report;
    onDelete: (reportId: string) => Promise<void>;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    rawXml?: string; // Raw XML içeriği için yeni prop
}

const CATEGORY_COLORS: Record<CategoryType, string> = {
    'dmarc-capable': '#009932',
    'forwarders': '#A0A0A2',
    'non-dmarc-capable': '#FF0000',
    'threats-unknown': '#9C27B0'
};

const CATEGORY_ICONS: Record<CategoryType, React.ReactNode> = {
    'dmarc-capable': <Shield className="h-5 w-5 text-green-600" />,
    'forwarders': <Forward className="h-5 w-5 text-gray-600" />,
    'non-dmarc-capable': <AlertTriangle className="h-5 w-5 text-yellow-600" />,
    'threats-unknown': <AlertCircle className="h-5 w-5 text-red-600" />
};

const CATEGORY_DESCRIPTIONS: Record<CategoryType, string> = {
    'dmarc-capable': 'DMARC uyumlu e-posta sunucuları',
    'forwarders': 'E-posta yönlendirme yapan sunucular',
    'non-dmarc-capable': 'DMARC uyumlu olmayan sunucular',
    'threats-unknown': 'Potansiyel tehditler veya bilinmeyen kaynaklar'
};

const DISPOSITION_COLORS = {
    reject: 'text-red-600',
    quarantine: 'text-yellow-600',
    none: 'text-green-600'
};

const AUTH_RESULT_ICONS = {
    pass: <CheckCircle2 className="h-4 w-4 text-green-600" />,
    fail: <XCircle className="h-4 w-4 text-red-600" />
};

// Stil sabitleri
const STYLE = {
    categoryCard: 'bg-white rounded-lg shadow-sm p-4 mb-4',
    categoryHeader: 'flex items-center space-x-2 text-lg font-medium mb-4',
    categoryIcon: 'h-6 w-6',
    detailsGrid: 'grid grid-cols-1 md:grid-cols-2 gap-4',
    statCard: 'bg-gray-50 rounded p-3',
    statLabel: 'text-sm text-gray-600',
    statValue: 'text-xl font-medium',
    authResultsGrid: 'grid grid-cols-2 gap-4 mt-2',
    authResultCard: 'bg-white p-3 rounded border',
    tooltip: 'absolute invisible group-hover:visible bg-gray-800 text-white text-sm rounded p-2 mt-8',
};

export const DmarcReport: React.FC<DmarcReportProps> = ({
    report,
    onDelete,
    activeTab,
    setActiveTab
}) => {
    // Record'u display formatına dönüştürme
    const processRecordForDisplay = (record: DmarcRecord): RecordDisplay => {
        const processedData = record.processedData;
        const row = record.row[0];
        const sourceIp = row.source_ip[0];
        const count = parseInt(row.count[0]);
        const headerFrom = record.identifiers[0].header_from[0];
        const disposition = row.policy_evaluated[0].disposition[0];
        const dkimResults = record.auth_results[0]?.dkim || [];
        const spfResults = record.auth_results[0]?.spf || [];
        const provider = getIpProvider(sourceIp);

        return {
            baseInfo: {
                sourceIp,
                provider,
                messageCount: count,
                headerFrom,
                disposition
            },
            authentication: {
                dkim: {
                    aligned: processedData?.details?.authenticatedWith?.dkim || false,
                    results: dkimResults.map(r => ({
                        domain: r.domain[0],
                        result: r.result[0],
                        selector: r.selector?.[0]
                    }))
                },
                spf: {
                    aligned: processedData?.details?.authenticatedWith?.spf || false,
                    results: spfResults.map(r => ({
                        domain: r.domain[0],
                        result: r.result[0]
                    }))
                }
            },
            category: {
                main: processedData?.category || 'threats-unknown',
                sub: processedData?.subCategory,
                details: processedData?.details?.forwardingType
            }
        };
    };

    // Pie chart için data hazırlama
    const chartData = {
      labels: Object.entries(report.stats.categoryTotals).map(([category, count]) => 
          `${category.toUpperCase()}`
      ),
      datasets: [{
          data: Object.values(report.stats.categoryTotals),
          backgroundColor: Object.keys(report.stats.categoryTotals).map(cat => 
              CATEGORY_COLORS[cat as CategoryType]
          )
      }]
  };

    // Chart options
    const chartOptions = {
      plugins: {
          legend: {
              position: 'right' as const,
              labels: {
                  boxWidth: 20,
                  padding: 20,
                  font: {
                      size: 12
                  },
                  generateLabels: (chart: any) => {
                      const datasets = chart.data.datasets[0];
                      const total = datasets.data.reduce((a: number, b: number) => a + b, 0);
                      
                      return chart.data.labels.map((label: string, i: number) => {
                          const count = datasets.data[i];
                          const percentage = ((count / total) * 100).toFixed(1);
                          return {
                              text: `${label} (${count} msg - ${percentage}%)`,
                              fillStyle: datasets.backgroundColor[i],
                              hidden: false,
                              index: i
                          };
                      });
                  }
              }
          },
          tooltip: {
            callbacks: {
                label: (context: any) => {
                    const value = context.raw || 0;
                    const percentage = ((value / report.stats.totalMessages) * 100).toFixed(1);
                    return `${value} msg (${percentage}%)`;
                }
            }
        }
      }
  };

    // Authentication sonuçlarını render etme
    const renderAuthResults = (record: RecordDisplay) => (
        <div className={STYLE.authResultsGrid}>
            <div>
                <h5 className="text-sm font-medium text-gray-600 mb-1">
                    DKIM
                    {record.authentication.dkim.aligned && (
                        <span className="ml-2 text-green-600">(Aligned)</span>
                    )}
                </h5>
                <div className="text-sm font-medium">
                    {record.authentication.dkim.results.map((result, idx) => (
                        <div key={idx} className="flex items-center space-x-2 mb-1">
                            {AUTH_RESULT_ICONS[result.result as 'pass' | 'fail']}
                            <span>{result.domain}</span>
                            {result.selector && (
                                <span className="text-gray-500">({result.selector})</span>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <h5 className="text-sm font-medium text-gray-600 mb-1">
                    SPF
                    {record.authentication.spf.aligned && (
                        <span className="ml-2 text-green-600">(Aligned)</span>
                    )}
                </h5>
                <div className="text-sm font-medium">
                    {record.authentication.spf.results.map((result, idx) => (
                        <div key={idx} className="flex items-center space-x-2 mb-1">
                            {AUTH_RESULT_ICONS[result.result as 'pass' | 'fail']}
                            <span>{result.domain}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    // Her bir kaydı render etme
    const renderRecord = (record: DmarcRecord) => {
        const displayRecord = processRecordForDisplay(record);
        
        return (
            <div className={STYLE.categoryCard} key={displayRecord.baseInfo.sourceIp}>
                <div className={STYLE.detailsGrid}>
                    {/* Sol Kolon - Temel Bilgiler */}
                    <div className="space-y-3">
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">IP Adresi:</span>
                            <div className="flex items-center space-x-2">
                                <Globe className="h-4 w-4 text-gray-400" />
                                <span className="font-medium">{displayRecord.baseInfo.sourceIp}</span>
                            </div>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-gray-600">Sağlayıcı:</span>
                            <div className="flex items-center space-x-2">
                                <Flag className="h-4 w-4 text-gray-400" />
                                <span className="font-medium">{displayRecord.baseInfo.provider}</span>
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-600">Mesaj Sayısı:</span>
                            <span className="font-medium">{displayRecord.baseInfo.messageCount} msg</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-600">Header From:</span>
                            <span className="font-medium">{displayRecord.baseInfo.headerFrom}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-600">Disposition:</span>
                            <span className={`font-medium ${DISPOSITION_COLORS[displayRecord.baseInfo.disposition as keyof typeof DISPOSITION_COLORS]}`}>
                                {displayRecord.baseInfo.disposition.toUpperCase()}
                            </span>
                        </div>
                        {displayRecord.category.sub && (
                            <div className="flex justify-between text-sm">
                                <span className="text-gray-600">Alt Kategori:</span>
                                <span className="font-medium">{displayRecord.category.sub}</span>
                            </div>
                        )}
                    </div>

                    {/* Sağ Kolon - Authentication Sonuçları */}
                    <div>
                        <h4 className="font-medium text-gray-700 mb-2">Kimlik Doğrulama Sonuçları</h4>
                        {renderAuthResults(displayRecord)}
                        
                        {displayRecord.category.details && (
                            <div className="mt-4 text-sm text-gray-600">
                                <p>{displayRecord.category.details}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Card className="w-full">
            <CardHeader className="flex flex-row items-center justify-between">
    <div>
        <h2 className="text-2xl font-semibold">
            DMARC Raporu: {report.policyPublished.domain}
        </h2>
        <p className="text-sm text-gray-500">
            {new Date(report.reportMetadata.dateRange.begin).toLocaleDateString()} - 
            {new Date(report.reportMetadata.dateRange.end).toLocaleDateString()}
        </p>
    </div>
    <div className="flex items-center gap-2">
        <RawXmlModal 
        xmlContent={report.rawXml || ''} 
        reportId={report.reportMetadata.reportId} 
        />
        <button 
            onClick={() => onDelete(report.reportMetadata.reportId)}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
        >
            Raporu Sil
        </button>
    </div>
</CardHeader>

            <CardContent className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {/* Metadata Section */}
                <div className="space-y-4">
                    <h3 className="text-lg font-medium">Rapor Detayları</h3>
                    <div className="bg-gray-50 rounded-lg p-4">
                        <dl className="space-y-2">
                            <div className="flex justify-between">
                                <dt>Organizasyon:</dt>
                                <dd>{report.reportMetadata.orgName}</dd>
                            </div>
                            <div className="flex justify-between">
                                <dt>Report ID:</dt>
                                <dd className="font-mono text-sm break-all">
                                    {report.reportMetadata.reportId}
                                </dd>
                            </div>
                            <div className="flex justify-between">
                                <dt>Email:</dt>
                                <dd className="text-sm break-all">{report.reportMetadata.email}</dd>
                            </div>
                            <div className="flex justify-between">
                                <dt>Policy:</dt>
                                <dd>{report.policyPublished.p}</dd>
                            </div>
                            <div className="flex justify-between">
                                <dt>Sub Policy:</dt>
                                <dd>{report.policyPublished.sp}</dd>
                            </div>
                            <div className="flex justify-between">
                                <dt>DKIM/SPF Alignment:</dt>
                                <dd>{`${report.policyPublished.adkim}/${report.policyPublished.aspf}`}</dd>
                            </div>
                            <div className="flex justify-between">
                                <dt>Percentage:</dt>
                                <dd>{report.policyPublished.pct}%</dd>
                            </div>
                        </dl>
                    </div>
                </div>

                {/* Chart & Stats Section */}
                <div className="space-y-4 col-span-2">
                    <h3 className="text-lg font-medium">Mesaj Dağılımı</h3>
                    <div className="bg-gray-50 rounded-lg p-4">
                {/* Grafik için dinamik bir yükseklik */}
                  <div className="relative" style={{ height: '120px' }}>
                    <Pie
                data={chartData}
                options={{
                    ...chartOptions,
                    responsive: true,
                    maintainAspectRatio: false // Yükseklik oranını konteynere göre ayarlar
                }}
            />
        </div>

                        <div className="mt-6 grid grid-cols-2 gap-4">
                            {/* Alignment Stats */}
                            <div className={STYLE.statCard}>
                                <div className={STYLE.statLabel}>DKIM Alignment</div>
                                <div className={STYLE.statValue}>
                                    {report.stats.alignmentRates.dkim.toFixed(1)}%
                                </div>
                            </div>
                            <div className={STYLE.statCard}>
                                <div className={STYLE.statLabel}>SPF Alignment</div>
                                <div className={STYLE.statValue}>
                                    {report.stats.alignmentRates.spf.toFixed(1)}%
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Category Tabs */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium">Kategoriler</h3>
                        <div className="flex flex-wrap gap-2">
                            {(Object.keys(report.stats.categoryTotals) as CategoryType[]).map((category) => {
                                const records = report.categorizedRecords[category] || [];
                                const count = report.stats.categoryTotals[category];
                                const isDisabled = records.length === 0;
                                
                                return (
                                    <div key={category} className="group relative">
                                        <button
                                            onClick={() => !isDisabled && setActiveTab(category)}
                                            className={`
                                                flex items-center space-x-2 px-4 py-2 rounded-md text-sm font-medium 
                                                transition-colors
                                                ${activeTab === category 
                                                    ? 'bg-blue-600 text-white' 
                                                    : isDisabled
                                                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                                                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                                }
                                            `}
                                            disabled={isDisabled}
                                        >
                                            {CATEGORY_ICONS[category]}
                                            <span>
                                                {category.replace('-', ' ').toUpperCase()} 
                                                ({count} msg)
                                            </span>
                                        </button>
                                        <div className={STYLE.tooltip}>
                                            {CATEGORY_DESCRIPTIONS[category]}
                                            {isDisabled && " - Bu kategoride kayıt bulunmuyor"}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                {/* Active Tab Content */}
{activeTab && (
    <div className="col-span-full space-y-4 mt-4">
        <div className={STYLE.categoryHeader}>
            {CATEGORY_ICONS[activeTab as CategoryType]}
            <h3 className="text-lg font-medium">
                {activeTab.replace('-', ' ').toUpperCase()} Detayları
            </h3>
            <span className="ml-2 text-sm text-gray-500">
                ({report.stats.categoryTotals[activeTab as CategoryType]} msg)
            </span>
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-sm text-gray-600 mb-4">
                {CATEGORY_DESCRIPTIONS[activeTab as CategoryType]}
            </p>
            <div className="space-y-4">
                {report.categorizedRecords[activeTab as CategoryType]?.map((record, index) => (
                    <div key={`${record.row[0].source_ip[0]}-${index}`}>
                        {renderRecord(record)}
                    </div>
                ))}
            </div>
            {(!report.categorizedRecords[activeTab as CategoryType] || 
              report.categorizedRecords[activeTab as CategoryType].length === 0) && (
                <div className="text-center py-8 text-gray-500">
                    Bu kategoride kayıt bulunmuyor
                </div>
            )}
        </div>
    </div>
)}
            </CardContent>
        </Card>
    );
};

export default DmarcReport;