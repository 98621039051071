// frontend/src/App.tsx

import React, { useState, useCallback, useEffect } from 'react';
import { 
  ref, 
  uploadBytesResumable, 
  getDownloadURL,
  UploadTaskSnapshot,
  deleteObject
} from "firebase/storage";
import { useAuthState } from 'react-firebase-hooks/auth';
import { 
  storage, 
  db, 
  auth, 
  deleteReport,
  cloudFunctionsUrl,
  collection,
  query,
  where,
  getDocs,
} from './firebase';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { 
  groupReportsByReceiverDomain, 
  groupReportsByOrganization 
} from './utils/reportUtils';

import { 
  DmarcReport,
  AppLayout,
  LoadingSpinner,
  FileUploadSection,
  ReportMenu 
} from './components';

import { Report, FileProgress, ProcessResponse } from './types';
import './App.css';

// Constants
const UPLOAD_MAX_SIZE = 10 * 1024 * 1024; // 10MB
const UPLOAD_MAX_TOTAL_SIZE = 50 * 1024 * 1024; // 50MB
const UPLOAD_MAX_FILES = 10;
const ALLOWED_FILE_TYPES = /\.(xml|zip|rar|gz)$/i;

ChartJS.register(ArcElement, Tooltip, Legend);

function App() {
  // State declarations
  const [user, loading, error] = useAuthState(auth);
  const [files, setFiles] = useState<File[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedReceiverDomain, setSelectedReceiverDomain] = useState<string | null>(null);
  const [selectedOrganization, setSelectedOrganization] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [message, setMessage] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>('dmarc-capable');
  const [fileProgress, setFileProgress] = useState<{[key: string]: FileProgress}>({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


// Fetch reports from Firestore
const fetchReports = useCallback(async () => {
  if (!user) return;

  try {
      setIsLoading(true);
      console.log('Fetching reports for user:', user.uid);
      
      const reportsQuery = query(
          collection(db, "dmarc_reports"),
          where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(reportsQuery);
      const fetchedReports = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
              reportMetadata: data.reportMetadata,
              policyPublished: data.policyPublished,
              records: data.records,
              categorizedRecords: data.categorizedRecords,
              stats: data.stats,
              userId: data.userId,
              originalFileName: data.originalFileName,
              uploadedAt: data.uploadedAt,
              rawXml: data.rawXml, // Eklenen alan
              id: doc.id
          } as Report;
      });
      
      console.log('Fetched reports:', fetchedReports.length);
      setReports(fetchedReports);
  } catch (error) {
      console.error("Error fetching reports:", error);
      setMessage("Raporlar yüklenirken hata oluştu");
  } finally {
      setIsLoading(false);
  }
}, [user]);

useEffect(() => {
  if (user) {
    fetchReports();
  } else {
    setReports([]);
    setSelectedReport(null);
    setSelectedReceiverDomain(null);
    setSelectedOrganization(null);
  }
}, [user, fetchReports]);

// Authentication handlers
const handleSignIn = async () => {
  try {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  } catch (error) {
    console.error("Sign in error:", error);
    setMessage("Giriş yapılırken hata oluştu");
  }
};

const handleSignOut = async () => {
  try {
    await auth.signOut();
    setReports([]);
    setSelectedReport(null);
    setSelectedReceiverDomain(null);
    setSelectedOrganization(null);
  } catch (error) {
    console.error("Sign out error:", error);
    setMessage("Çıkış yapılırken hata oluştu");
  }
};

// File handlers
const handleFileChange = useCallback((files: File[]) => {
  const newProgress: {[key: string]: FileProgress} = {};
  
  files.forEach(file => {
    newProgress[file.name] = {
      status: 'waiting',
      progress: 0
    };
  });
  
  console.log('New files selected:', files.length);
  setFileProgress(newProgress);
  setFiles(files);
}, []);

const cleanupTempFile = async (tempStorageRef: any) => {
  try {
    await deleteObject(tempStorageRef);
    console.log('Temporary file cleaned up successfully');
  } catch (error) {
    console.warn('Error cleaning up temporary file:', error);
  }
};

const handleProcessResponse = (data: ProcessResponse, fileName: string) => {
  console.log('Processing response:', data);
  
  data.results.forEach((result) => {
    setFileProgress(prev => ({
      ...prev,
      [result.fileName]: {
        ...prev[result.fileName],
        status: result.status as FileProgress['status'],
        progress: 100,
        error: result.error,
        reportId: result.reportId
      }
    }));
  });

  setMessage(data.message || `${fileName} başarıyla işlendi`);
};
const processFile = async (file: File, idToken: string): Promise<void> => {
  console.log('File type:', file.type); 
  console.log('File name:', file.name);
  
  const tempFileName = `${Date.now()}_${file.name}`;
  const tempStorageRef = ref(storage, `temp/${user!.uid}/${tempFileName}`);
  let downloadURL: string | null = null;

  try {
    console.log('Starting upload for:', file.name);
    const uploadTask = uploadBytesResumable(tempStorageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot: UploadTaskSnapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload progress:', progress);
        setFileProgress(prev => ({
          ...prev,
          [file.name]: {
            ...prev[file.name],
            status: 'uploading',
            progress: Math.round(progress)
          }
        }));
      },
      (error) => {
        console.error('Upload error details:', error);
        setFileProgress(prev => ({
          ...prev,
          [file.name]: {
            ...prev[file.name],
            status: 'error',
            progress: 0,
            error: error.message
          }
        }));
        cleanupTempFile(tempStorageRef);
      },
      async () => {
        try {
          downloadURL = await getDownloadURL(tempStorageRef);
          console.log('Download URL obtained:', downloadURL);
          
          // Force yeni token al
          let token = await user!.getIdToken(true);
          console.log('Token (first 10 chars):', token.substring(0, 10));

          setFileProgress(prev => ({
            ...prev,
            [file.name]: {
              ...prev[file.name],
              status: 'processing',
              progress: 100,
            }
          }));

          const makeRequest = async (currentToken: string) => {
            const response = await fetch(`${cloudFunctionsUrl}/process`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${currentToken}`,
              },
              body: JSON.stringify({
                fileUrl: downloadURL,
                fileName: file.name
              })
            });
            console.log('API Response status:', response.status);
            return response;
          };

          let response = await makeRequest(token);
          
          // 401 hatası durumunda token'ı yenile ve tekrar dene
          if (response.status === 401) {
            console.log('Token expired, refreshing...');
            token = await user!.getIdToken(true);
            response = await makeRequest(token);
          }

          if (!response.ok) {
            const errorText = await response.text();
            console.error('API Error response:', errorText);
            throw new Error(`API Error: ${response.status}`);
          }

          const data: ProcessResponse = await response.json();
          handleProcessResponse(data, file.name);
          await fetchReports();

        } catch (error: any) {
          console.error(`Processing error for ${file.name}:`, error);
          setFileProgress(prev => ({
            ...prev,
            [file.name]: {
              ...prev[file.name],
              status: 'error',
              progress: 0,
              error: error.message || 'Bilinmeyen bir hata oluştu'
            }
          }));
          
          if (downloadURL) {
            await cleanupTempFile(tempStorageRef);
          }
        }
      }
    );
  } catch (error: any) {
    console.error(`Error with ${file.name}:`, error);
    setFileProgress(prev => ({
      ...prev,
      [file.name]: {
        ...prev[file.name],
        status: 'error',
        progress: 0,
        error: error.message || 'Bilinmeyen bir hata oluştu'
      }
    }));
    
    if (downloadURL) {
      await cleanupTempFile(tempStorageRef);
    }
  }
};

const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  if (files.length === 0 || !user) {
    setMessage('Lütfen dosya seçin ve giriş yapın');
    return;
  }

  try {
    const idToken = await user.getIdToken();
    
    if (files.length > UPLOAD_MAX_FILES) {
      setMessage(`En fazla ${UPLOAD_MAX_FILES} dosya aynı anda yüklenebilir`);
      return;
    }

    const totalSize = files.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > UPLOAD_MAX_TOTAL_SIZE) {
      setMessage('Toplam dosya boyutu 50MB\'ı geçemez');
      return;
    }

    for (const file of files) {
      if (file.size > UPLOAD_MAX_SIZE) {
        setMessage(`${file.name} dosyası 10MB'dan büyük olamaz`);
        continue;
      }

      if (!file.name.match(ALLOWED_FILE_TYPES)) {
        setMessage(`${file.name} desteklenmeyen bir dosya formatı`);
        continue;
      }

      setMessage(`${file.name} yükleniyor...`);
      setFileProgress(prev => ({
        ...prev,
        [file.name]: {
          status: 'waiting',
          progress: 0
        }
      }));
      await processFile(file, idToken);
    }
  } catch (error) {
    console.error('Submit error:', error);
    setMessage('Dosya yükleme işlemi başarısız oldu');
  }
};

const handleDeleteReport = useCallback(async (reportId: string) => {
  if (!window.confirm('Bu raporu silmek istediğinizden emin misiniz?')) {
    return;
  }

  try {
    setIsLoading(true);
    await deleteReport(reportId);

    setReports(prevReports => {
      const updatedReports = prevReports.filter(
        report => report?.reportMetadata?.reportId !== reportId
      );

      // Silinen rapor seçili ise, state'i temizle
      if (selectedReport?.reportMetadata?.reportId === reportId) {
        setSelectedReport(null);
        setMessage('Rapor başarıyla silindi');

        // Seçili domain için başka rapor var mı kontrol et
        const domainReports = updatedReports.filter(
          report => report?.policyPublished?.domain === selectedReceiverDomain
        );

        if (domainReports.length === 0) {
          setSelectedReceiverDomain(null);
          setSelectedOrganization(null);
        }
      }

      return updatedReports;
    });

  } catch (error) {
    console.error('Rapor silinirken hata:', error);
    setMessage('Rapor silinirken bir hata oluştu. Lütfen tekrar deneyin.');
  } finally {
    setIsLoading(false);
  }
}, [selectedReport, selectedReceiverDomain]);
const handleDeleteByDomain = useCallback(async (domain: string) => {
  if (!window.confirm(`${domain} domainine ait tüm raporları silmek istediğinizden emin misiniz?`)) {
    return;
  }

  try {
    setIsLoading(true);
    setMessage(`${domain} raporları siliniyor...`);

    const domainReports = reports.filter(report => 
      report?.policyPublished?.domain === domain
    );

    await Promise.all(
      domainReports.map(report => 
        report?.reportMetadata?.reportId ? deleteReport(report.reportMetadata.reportId) : Promise.resolve()
      )
    );

    setReports(prevReports => {
      const updatedReports = prevReports.filter(report => 
        report?.policyPublished?.domain !== domain
      );
      
      if (updatedReports.length === 0) {
        setSelectedReceiverDomain(null);
        setSelectedOrganization(null);
        setSelectedReport(null);
        setIsMenuOpen(false);
        setMessage('Tüm raporlar silindi');
      }
      
      return updatedReports;
    });

  } catch (error) {
    console.error('Domain silme hatası:', error);
    setMessage('Raporlar silinirken bir hata oluştu');
  } finally {
    setIsLoading(false);
  }
}, [reports]); 

const handleDeleteByOrganization = useCallback(async (organization: string) => {
  if (!window.confirm(`${organization} organizasyonuna ait tüm raporları silmek istediğinizden emin misiniz?`)) {
    return;
  }

  try {
    setIsLoading(true);
    setMessage(`${organization} raporları siliniyor...`);

    const orgReports = reports.filter(report => 
      report?.reportMetadata?.orgName === organization && 
      report?.policyPublished?.domain === selectedReceiverDomain
    );

    await Promise.all(
      orgReports.map(report => 
        report?.reportMetadata?.reportId ? deleteReport(report.reportMetadata.reportId) : Promise.resolve()
      )
    );

    setReports(prevReports => 
      prevReports.filter(report => 
        !(report?.reportMetadata?.orgName === organization && 
          report?.policyPublished?.domain === selectedReceiverDomain)
      )
    );

    setSelectedOrganization(null);
    setSelectedReport(null);
    setMessage(`${organization} organizasyonunun tüm raporları başarıyla silindi`);

  } catch (error) {
    console.error('Organizasyon silme hatası:', error);
    setMessage('Raporlar silinirken bir hata oluştu');
  } finally {
    setIsLoading(false);
  }
}, [reports, selectedReceiverDomain]);
// Loading and error states
if (loading) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <LoadingSpinner size="large" />
    </div>
  );
}

if (error) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-red-600">Hata: {error.message}</div>
    </div>
  );
}

// Filter and group reports
const validReports = reports.filter(report => 
  report && report.policyPublished && report.policyPublished.domain
);

const groupedReports = groupReportsByReceiverDomain(validReports);
const receiverDomains = Object.keys(groupedReports);

return (
  <AppLayout
    user={user}
    onSignIn={handleSignIn}
    onSignOut={handleSignOut}
  >
    {user ? (
      <div className="space-y-6">
        <FileUploadSection
          onFileChange={handleFileChange}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          files={files}
          fileProgress={fileProgress}
          message={message}
        />

        <ReportMenu
          isOpen={isMenuOpen}
          onToggle={() => setIsMenuOpen(!isMenuOpen)}
          receiverDomains={receiverDomains}
          selectedDomain={selectedReceiverDomain}
          onDomainSelect={setSelectedReceiverDomain}
          organizations={selectedReceiverDomain 
            ? Object.keys(groupReportsByOrganization(groupedReports[selectedReceiverDomain]))
            : []}
          selectedOrg={selectedOrganization}
          onOrgSelect={setSelectedOrganization}
          reports={selectedReceiverDomain && selectedOrganization 
            ? groupReportsByOrganization(groupedReports[selectedReceiverDomain])[selectedOrganization]
            : []}
          selectedReport={selectedReport}
          onReportSelect={setSelectedReport}
          onDeleteReport={handleDeleteReport}
          onDeleteDomain={handleDeleteByDomain}
          onDeleteOrganization={handleDeleteByOrganization}
        />

        {selectedReport && (
          <DmarcReport
            report={selectedReport}
            onDelete={handleDeleteReport}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </div>
    ) : (
      <div className="flex items-center justify-center h-[calc(100vh-4rem)]">
        <div className="text-center space-y-4">
          <p className="text-xl text-gray-600">
            Lütfen raporlarınızı görmek için giriş yapın.
          </p>
          <button
            onClick={handleSignIn}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Google ile Giriş Yap
          </button>
        </div>
      </div>
    )}
  </AppLayout>
);
}

export default App;