// frontend/src/utils/reportUtils.ts
import { Report, DmarcRecord } from '../types';
import ipProviderRanges from '../data/ipProviderRanges.json';

// IP adresini numeric değere dönüştürme
const ipToLong = (ip: string): number => {
    return ip.split('.')
        .reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0) >>> 0;
};

// IP adresi range kontrolü
const isIpInRange = (ip: string, start: string, end: string): boolean => {
    const ipLong = ipToLong(ip);
    const startLong = ipToLong(start);
    const endLong = ipToLong(end);
    return ipLong >= startLong && ipLong <= endLong;
};

// IP provider tespiti
export const getIpProvider = (ip: string): string => {
    // Tüm provider'ları kontrol et
    for (const [provider, ranges] of Object.entries(ipProviderRanges)) {
        const isInRange = ranges.some(range => 
            isIpInRange(ip, range.start, range.end)
        );
        if (isInRange) return provider;
    }

    return 'Unknown';
};

// DKIM alignment kontrolü
export const checkDkimAlignment = (dkimResults: any[], headerFrom: string): boolean => {
    return dkimResults.some(result => {
        const dkimDomain = result.domain[0].toLowerCase();
        const fromDomain = headerFrom.toLowerCase();
        // DKIM Relaxed alignment kontrolü
        return dkimDomain === fromDomain || 
               fromDomain.endsWith(`.${dkimDomain}`) || 
               dkimDomain.endsWith(`.${fromDomain}`);
    });
};

// SPF alignment kontrolü
export const checkSpfAlignment = (spfResults: { domain: string[] }[], headerFrom: string): boolean => {
    return spfResults.some(result => {
        if (!result.domain?.[0]) return false;
        const spfDomain = result.domain[0].toLowerCase();
        const fromDomain = headerFrom.toLowerCase();
        // SPF Relaxed alignment kontrolü
        return spfDomain === fromDomain || 
               fromDomain.endsWith(`.${spfDomain}`) || 
               spfDomain.endsWith(`.${fromDomain}`);
    });
};

// Record kategorizasyonu
export const categorizeRecord = (record: DmarcRecord) => {
    if (!record || !record.row || !record.row[0]) {
        return {
            category: 'threats-unknown',
            count: 0,
            subCategory: 'invalid-record'
        };
    }

    try {
        const count = parseInt(record.row[0].count[0]) || 0;
        const policyEvaluated = record.row[0].policy_evaluated[0];
        const dkimResults = record.auth_results[0]?.dkim || [];
        const spfResults = record.auth_results[0]?.spf || [];
        const headerFrom = record.identifiers[0]?.header_from[0];

        if (!headerFrom || !policyEvaluated) {
            return {
                category: 'threats-unknown',
                count,
                subCategory: 'missing-data'
            };
        }

        const dkimAligned = checkDkimAlignment(dkimResults, headerFrom);
        const spfAligned = checkSpfAlignment(spfResults, headerFrom);
        const dkimResult = policyEvaluated.dkim[0];
        const spfResult = policyEvaluated.spf[0];
        const sourceIp = record.row[0].source_ip[0];
        const provider = getIpProvider(sourceIp);
        const disposition = policyEvaluated.disposition[0];

        // 1. Threats/Unknown Detection
        // Eğer hem SPF hem DKIM fail ve disposition quarantine/reject ise
        if (dkimResult === 'fail' && spfResult === 'fail' && 
            (disposition === 'quarantine' || disposition === 'reject')) {
            return {
                category: 'threats-unknown',
                count,
                subCategory: 'suspicious-authentication',
                details: {
                    disposition,
                    provider
                }
            };
        }

        // 2. DMARC Capable Check
        // En az biri aligned ve pass ise
        if ((dkimResult === 'pass' && dkimAligned) || (spfResult === 'pass' && spfAligned)) {
            return {
                category: 'dmarc-capable',
                count,
                details: {
                    dkimPass: dkimResult === 'pass' && dkimAligned,
                    spfPass: spfResult === 'pass' && spfAligned,
                    provider
                }
            };
        }

        // 3. Forwarders Detection
        // DKIM pass + aligned, SPF fail (Gmail özel kontrolü)
        if (dkimResult === 'pass' && dkimAligned && spfResult === 'fail') {
            if (provider === 'GMAIL') {
                return {
                    category: 'forwarders',
                    count,
                    subCategory: 'gmail-forwarding',
                    details: {
                        provider: 'Gmail',
                        dkimSurvival: true
                    }
                };
            }
            return {
                category: 'forwarders',
                count,
                subCategory: 'preserve-dkim',
                details: {
                    provider,
                    dkimSurvival: true
                }
            };
        }

        // 4. Non-DMARC Capable
        // Authentication var ama alignment yok
        if (!dkimAligned && !spfAligned && disposition === 'none') {
            return {
                category: 'non-dmarc-capable',
                count,
                details: {
                    provider,
                    dkimResult,
                    spfResult
                }
            };
        }

        // 5. Default: Threats/Unknown
        return {
            category: 'threats-unknown',
            count,
            subCategory: 'unclassified',
            details: {
                disposition,
                provider
            }
        };

    } catch (error) {
        console.error('Error categorizing record:', error);
        return {
            category: 'threats-unknown',
            count: parseInt(record.row[0].count[0]) || 0,
            subCategory: 'error',
            details: { error: (error as Error).message }
        };
    }
};

export const groupReportsByReceiverDomain = (reports: Report[]) => {
    // Array kontrolü
    if (!reports || !Array.isArray(reports)) return {};

    return reports.reduce((acc, report) => {
        const domain = report?.policyPublished?.domain;
        if (!domain) {
            console.warn('Report without domain found:', report);
            return acc;
        }
        
        if (!acc[domain]) {
            acc[domain] = [];
        }
        acc[domain].push(report);
        return acc;
    }, {} as Record<string, Report[]>);
};

export const groupReportsByOrganization = (reports: Report[]) => {
    // Array kontrolü
    if (!reports || !Array.isArray(reports)) return {};

    return reports.reduce((acc, report) => {
        const orgName = report?.reportMetadata?.orgName;
        if (!orgName) {
            console.warn('Report without organization found:', report);
            return acc;
        }

        if (!acc[orgName]) {
            acc[orgName] = [];
        }
        acc[orgName].push(report);
        return acc;
    }, {} as Record<string, Report[]>);
};

export const sortReportsByDateRange = (reports: Report[]) => {
    // Array kontrolü
    if (!reports || !Array.isArray(reports)) return [];

    return [...reports].sort((a, b) => {
        const dateA = new Date(a?.reportMetadata?.dateRange?.begin || 0).getTime();
        const dateB = new Date(b?.reportMetadata?.dateRange?.begin || 0).getTime();
        return dateB - dateA;
    });
};

export const calculateMessageTotals = (records: DmarcRecord[]) => {
    // Null veya undefined kontrolü
    if (!records || !Array.isArray(records)) return { total: 0 };

    return records.reduce((totals: Record<string, number>, record) => {
        if (!record || !record.row?.[0]?.count?.[0]) return totals;
        
        const category = categorizeRecord(record);
        const count = parseInt(record.row[0].count[0]) || 0;

        totals.total = (totals.total || 0) + count;
        totals[category.category] = (totals[category.category] || 0) + count;
        
        return totals;
    }, { total: 0 } as Record<string, number>);
};

export const calculateAlignmentRates = (records: DmarcRecord[]) => {
    // Array ve boşluk kontrolü
    if (!records || !Array.isArray(records) || records.length === 0) {
        return {
            dkimAlignmentRate: 0,
            spfAlignmentRate: 0,
            dmarcPassRate: 0
        };
    }

    let totalMessages = 0;
    let dkimAlignedMessages = 0;
    let spfAlignedMessages = 0;
    let dmarcPassMessages = 0;

    records.forEach(record => {
        // Her kayıt için null kontrolleri
        if (!record?.row?.[0]?.count?.[0]) return;
        
        const messageCount = parseInt(record.row[0].count[0]) || 0;
        totalMessages += messageCount;

        const dkimResults = record.auth_results?.[0]?.dkim || [];
        const spfResults = record.auth_results?.[0]?.spf || [];
        const headerFrom = record.identifiers?.[0]?.header_from?.[0];
        const policyEvaluated = record.row[0]?.policy_evaluated?.[0];

        if (headerFrom && dkimResults && checkDkimAlignment(dkimResults, headerFrom) && 
            policyEvaluated?.dkim?.[0] === 'pass') {
            dkimAlignedMessages += messageCount;
        }

        if (headerFrom && spfResults && checkSpfAlignment(spfResults, headerFrom) && 
            policyEvaluated?.spf?.[0] === 'pass') {
            spfAlignedMessages += messageCount;
        }

        const category = categorizeRecord(record);
        if (category.category === 'dmarc-capable') {
            dmarcPassMessages += messageCount;
        }
    });

    return {
        dkimAlignmentRate: totalMessages ? (dkimAlignedMessages / totalMessages) * 100 : 0,
        spfAlignmentRate: totalMessages ? (spfAlignedMessages / totalMessages) * 100 : 0,
        dmarcPassRate: totalMessages ? (dmarcPassMessages / totalMessages) * 100 : 0
    };
};