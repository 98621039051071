// frontend/src/components/FileUpload.tsx
import React, { useCallback } from 'react';
import { Upload } from 'lucide-react';
import JSZip from 'jszip';

interface FileUploadProps {
  onFileChange: (files: File[]) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
}

export const FileUpload: React.FC<FileUploadProps> = ({ 
  onFileChange, 
  onSubmit, 
  isLoading 
}) => {
  const handleFileSelect = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const selectedFiles = Array.from(e.target.files);
    const xmlFiles: File[] = [];
    
    for (const file of selectedFiles) {
      if (file.name.toLowerCase().endsWith('.xml')) {
        xmlFiles.push(file);
        continue;
      }

      if (file.name.match(/\.(zip|rar|gz)$/i)) {
        if (file.name.toLowerCase().endsWith('.gz')) {
          // GZ dosyalarını direkt gönder
          xmlFiles.push(file);
          continue;
        }

        try {
          const zip = new JSZip();
          const zipContent = await zip.loadAsync(file);
          
          for (const [fileName, zipEntry] of Object.entries(zipContent.files)) {
            if (fileName.toLowerCase().endsWith('.xml') && !zipEntry.dir) {
              const content = await zipEntry.async('blob');
              const xmlFile = new File(
                [content],
                `${file.name}_${fileName}`,
                { type: 'application/xml' }
              );
              xmlFiles.push(xmlFile);
            }
          }
        } catch (error) {
          console.error(`Error processing compressed file ${file.name}:`, error);
        }
      }
    }

    onFileChange(xmlFiles);
  }, [onFileChange]);

  return (
    <form onSubmit={onSubmit} className="flex items-center space-x-4">
      <input
        type="file"
        multiple
        onChange={handleFileSelect}
        accept=".xml,.zip,.rar,.gz"
        className="flex-1 p-2 border border-gray-300 rounded"
      />
      <button
        type="submit"
        disabled={isLoading}
        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
      >
        <Upload className="h-4 w-4 mr-2" />
        Yükle ve Analiz Et
      </button>
    </form>
  );
};

export default FileUpload;