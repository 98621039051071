// frontend/src/components/AppLayout.tsx
import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';

interface AppLayoutProps {
  children: React.ReactNode;
  user: any;
  onSignIn: () => Promise<void>;
  onSignOut: () => Promise<void>;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  user,
  onSignIn,
  onSignOut
}) => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Header 
        user={user} 
        onSignIn={onSignIn} 
        onSignOut={onSignOut} 
        className="fixed top-0 left-0 right-0 z-50 bg-gray-900 shadow-lg"
      />
      <main className="container mx-auto px-4 flex-grow mt-[72px] mb-[64px] py-6">
        {children}
      </main>
      <Footer className="fixed bottom-0 left-0 right-0 z-50 bg-gray-900 shadow-lg" />
    </div>
  );
};