// frontend/src/components/ReportMenu.tsx
import React from 'react';
import { FaWindowRestore } from 'react-icons/fa';
import { Report } from '../types';
import { sortReportsByDateRange } from '../utils/reportUtils';

interface ReportMenuProps {
  isOpen: boolean;
  onToggle: () => void;
  receiverDomains: string[];
  selectedDomain: string | null;
  onDomainSelect: (domain: string) => void;
  organizations: string[];
  selectedOrg: string | null;
  onOrgSelect: (org: string) => void;
  reports: Report[];
  selectedReport: Report | null;
  onReportSelect: (report: Report) => void;
  onDeleteReport: (id: string) => Promise<void>;
  onDeleteDomain: (domain: string) => Promise<void>;
  onDeleteOrganization: (org: string) => Promise<void>;
}

export const ReportMenu: React.FC<ReportMenuProps> = ({
  isOpen,
  onToggle,
  receiverDomains,
  selectedDomain,
  onDomainSelect,
  organizations,
  selectedOrg,
  onOrgSelect,
  reports,
  selectedReport,
  onReportSelect,
  onDeleteReport,
  onDeleteDomain,
  onDeleteOrganization,
}) => {

  const formatDateRange = (beginDate?: string, endDate?: string) => {
    if (!beginDate || !endDate) return 'Tarih bilgisi yok';
    const begin = new Date(beginDate);
    const end = new Date(endDate);
    return `${begin.toLocaleDateString('tr-TR')} - ${end.toLocaleDateString('tr-TR')}`;
  };

  const sortedReports = React.useMemo(() => {
    return reports && Array.isArray(reports) ? sortReportsByDateRange(reports) : [];
  }, [reports]);

  return (
    <div className="space-y-4">
      <button 
        onClick={onToggle}
        className="menu-button"
      >
        <FaWindowRestore className="h-5 w-5" />
        <span>Raporlar</span>
      </button>
      
      {isOpen && (
        <div className="report-menu grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="receiver-domain-selection md:col-span-3">
            <h3>Alıcı Domainler</h3>
            <ul className="divide-y divide-gray-200">
              {receiverDomains?.map(domain => (
                <li
                  key={domain}
                  className={`flex justify-between items-center p-2 ${
                    selectedDomain === domain ? 'bg-gray-100' : ''
                  }`}
                >
                  <span
                    className="truncate cursor-pointer flex-1"
                    onClick={() => onDomainSelect(domain)}
                  >
                    {domain}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteDomain(domain);
                    }}
                    className="delete-button-small"
                  >
                    Sil
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {selectedDomain && (
            <div className="organization-selection md:col-span-4">
              <h3>Gönderen Organizasyonlar</h3>
              <ul className="divide-y divide-gray-200">
                {organizations?.map(org => (
                  <li
                    key={org}
                    className={`${selectedOrg === org ? 'selected' : ''}`}
                  >
                    <div className="flex justify-between items-center">
                      <span 
                        className="truncate cursor-pointer flex-1"
                        onClick={() => onOrgSelect(org)}
                      >
                        {org}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteOrganization(org);
                        }}
                        className="delete-button-small ml-2"
                      >
                        Sil
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {selectedDomain && selectedOrg && (
            <div className="report-list md:col-span-5">
              <h3>Raporlar</h3>
              <ul className="divide-y divide-gray-200">
                {sortedReports.map(report => {
                  const reportId = report?.reportMetadata?.reportId;
                  const dateRange = report?.reportMetadata?.dateRange;
                  
                  if (!reportId || !dateRange) return null;

                  return (
                    <li
                      key={reportId}
                      className={`flex justify-between items-center ${
                        selectedReport?.reportMetadata?.reportId === reportId ? 'selected' : ''
                      }`}
                    >
                      <span
                        onClick={() => onReportSelect(report)}
                        className="flex-1 cursor-pointer truncate"
                      >
                        {formatDateRange(dateRange.begin, dateRange.end)}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteReport(reportId);
                        }}
                        className="delete-button-small ml-2"
                      >
                        Sil
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportMenu;