// frontend/src/components/Footer.tsx
import React, { useState } from 'react';
import { VERSION } from '../version';
import { ChangelogModal } from './ChangelogModal';

interface FooterProps {
  className?: string;
}

export const Footer: React.FC<FooterProps> = ({ className = "" }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changelogContent, setChangelogContent] = useState('');

  const fetchChangelog = async () => {
    try {
      const response = await fetch('/README.md');
      const content = await response.text();
      setChangelogContent(content);
    } catch (error) {
      console.error('Error fetching changelog:', error);
      setChangelogContent('Versiyon geçmişi yüklenirken hata oluştu');
    }
  };

  const handleVersionClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!changelogContent) {
      await fetchChangelog();
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <footer className={`p-4 ${className}`}>
        <div className="container mx-auto text-center">
          <p className="text-white">
            © {new Date().getFullYear()} imba DMARC Analyzer App - 
            <button 
              onClick={handleVersionClick}
              className="ml-1 text-blue-400 hover:text-blue-300 underline focus:outline-none"
            >
              Version {VERSION}
            </button>
          </p>
        </div>
      </footer>
      <ChangelogModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        content={changelogContent}
      />
    </>
  );
};

export default Footer;