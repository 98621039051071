// frontend/src/components/UploadProgress.tsx
import React from 'react';
import { AlertCircle, CheckCircle, XCircle, Archive, FileText } from 'lucide-react';
import { FileProgress } from '../types';

interface UploadProgressProps {
  files: { [key: string]: FileProgress };
  message: string;
}

export const UploadProgress: React.FC<UploadProgressProps> = ({ files, message }) => {
  const getStatusIcon = (status: FileProgress['status'], progress: number) => {
    switch (status) {
      case 'success':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case 'error':
        return <XCircle className="h-5 w-5 text-red-500" />;
      case 'duplicate':
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
      case 'extracting':
        return <Archive className="h-5 w-5 text-blue-500 animate-pulse" />;
      case 'processing':
        return <FileText className="h-5 w-5 text-blue-500 animate-pulse" />;
      default:
        return <span className="text-sm text-blue-500">{progress}%</span>;
    }
  };

  const getStatusMessage = (status: FileProgress['status']) => {
    switch (status) {
      case 'success':
        return 'Rapor başarıyla işlendi ve geçici dosyalar temizlendi';
      case 'duplicate':
        return 'Bu rapor zaten mevcut';
      case 'extracting':
        return 'Dosya çıkarılıyor...';
      case 'processing':
        return 'Rapor işleniyor...';
      default:
        return '';
    }
  };

  return (
    <div className="mt-4 space-y-4">
      {message && (
        <div className="mb-4 text-sm text-gray-600">
          {message}
        </div>
      )}
      
      <div className="space-y-4">
        {Object.entries(files).map(([fileName, fileData]) => (
          <div key={fileName} className="space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <span className="text-sm font-medium truncate block">
                  {fileName}
                </span>
                {fileData.reportId && (fileData.status === 'success' || fileData.status === 'duplicate') && (
                  <span className="text-xs text-gray-500">
                    Report ID: {fileData.reportId}
                  </span>
                )}
                {/* Status message */}
                <span className="text-xs text-gray-500 block">
                  {getStatusMessage(fileData.status)}
                </span>
              </div>
              <span className="ml-2">
                {getStatusIcon(fileData.status, fileData.progress)}
              </span>
            </div>
            
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div 
                className={`h-2 rounded-full transition-all duration-300 ${
                  fileData.status === 'success' ? 'bg-green-500' :
                  fileData.status === 'error' ? 'bg-red-500' :
                  fileData.status === 'duplicate' ? 'bg-yellow-500' :
                  'bg-blue-500'
                }`}
                style={{ width: `${fileData.progress}%` }}
              />
            </div>

            {fileData.error && (
              <p className="text-xs text-red-600 mt-1">
                Hata: {fileData.error}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadProgress;