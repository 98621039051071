// frontend/src/firebase.ts

import { initializeApp } from "firebase/app";
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getDocs,
  deleteDoc,
  doc,
  getDoc,
  setDoc
} from "firebase/firestore";
import { 
  getStorage, 
  ref, 
  deleteObject 
} from "firebase/storage";
import { getAuth, } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export const cloudFunctionsUrl = (process.env.REACT_APP_CLOUD_FUNCTIONS_URL || 'https://us-central1-dmarcimba.cloudfunctions.net/api')
  .replace(/\/+$/, '');

export const getReport = async (reportId: string) => {
  try {
    const reportRef = doc(db, "dmarc_reports", reportId);
    const reportDoc = await getDoc(reportRef);

    if (reportDoc.exists()) {
      const data = reportDoc.data();
      // XML içeriğini de ekleyerek mevcut veriyi döndür
      return {
        ...data,
        rawXml: data.rawXml || '', // XML içeriği varsa al, yoksa boş string döndür
        id: reportDoc.id
      };
    }
    
    return null;
  } catch (error) {
    console.error("Error getting report:", error);
    throw error;
  }
};

export const deleteReport = async (reportId: string) => {
  try {
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('Kullanıcı girişi yapılmamış');
    }

    // Önce Firestore'dan raporu al
    const reportRef = doc(db, "dmarc_reports", reportId);
    const reportDoc = await getDoc(reportRef);

    if (!reportDoc.exists()) {
      throw new Error('Rapor bulunamadı');
    }

    const reportData = reportDoc.data();

    // Kullanıcı yetkisi kontrolü
    if (reportData.userId !== user.uid) {
      throw new Error('Bu raporu silme yetkiniz yok');
    }

    // Storage'dan XML dosyasını sil
    try {
      const storageRef = ref(storage, `dmarc_reports/${user.uid}/${reportId}.xml`);
      await deleteObject(storageRef);
      console.log(`Storage file for ${reportId} deleted successfully`);
    } catch (storageError) {
      console.warn('Storage delete error:', storageError);
      // Storage hatası olsa bile Firestore silme işlemine devam et
    }

    // Firestore'dan raporu sil
    await deleteDoc(reportRef);
    console.log(`Report ${reportId} deleted successfully`);

  } catch (error) {
    console.error("Error deleting report:", error);
    throw error;
  }
};

// Cloud Functions'a rapor yükleme ve XML içeriğini kaydetme
export const uploadReportWithXml = async (file: File) => {
  try {
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('Kullanıcı girişi yapılmamış');
    }

    // XML dosyasını oku
    const xmlContent = await file.text();
    
    // Benzersiz bir reportId oluştur
    const reportId = `report_${Date.now()}`;
    
    // Cloud Functions'a XML'i gönder ve işle
    const response = await fetch(`${cloudFunctionsUrl}/upload`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await user.getIdToken()}`
      },
      body: JSON.stringify({
        xmlContent,
        reportId,
        userId: user.uid
      })
    });

    if (!response.ok) {
      throw new Error('Rapor yüklenirken bir hata oluştu');
    }

    const processedData = await response.json();

    // Firestore'a işlenmiş veriyi ve raw XML'i kaydet
    await setDoc(doc(db, "dmarc_reports", reportId), {
      ...processedData,
      rawXml: xmlContent, // Raw XML'i kaydet
      userId: user.uid,
      uploadDate: new Date().toISOString()
    });

    return reportId;
  } catch (error) {
    console.error("Error uploading report:", error);
    throw error;
  }
};

// Firebase servisleri ve metodları tek bir exports objesi olarak dışa aktarılıyor
export {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc
};

// Firebase instance'larını ayrıca export ediyoruz
export { db, storage, auth };