// frontend/src/components/FileUploadSection.tsx
import React from 'react';
import FileUpload from './FileUpload';
import UploadProgress from './UploadProgress';
import { AlertCircle } from 'lucide-react';
import { FileProgress } from '../types';

interface FileUploadSectionProps {
  onFileChange: (files: File[]) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  isLoading: boolean;
  files: File[];
  fileProgress: { [key: string]: FileProgress };
  message: string;
}

export const FileUploadSection: React.FC<FileUploadSectionProps> = ({
  onFileChange,
  onSubmit,
  isLoading,
  files,
  fileProgress,
  message
}) => {
  const getUploadStats = () => {
    const stats = {
      total: Object.keys(fileProgress).length,
      completed: 0,
      failed: 0,
      inProgress: 0,
      duplicate: 0
    };

    Object.values(fileProgress).forEach(file => {
      if (file.status === 'success') stats.completed++;
      else if (file.status === 'error') stats.failed++;
      else if (file.status === 'duplicate') stats.duplicate++;
      else if (['uploading', 'extracting', 'processing'].includes(file.status)) stats.inProgress++;
    });

    return stats;
  };

  const stats = getUploadStats();

  return (
    <div className="space-y-4">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="mb-4">
          <h2 className="text-lg font-medium text-gray-900">Dosya Yükleme</h2>
          <p className="mt-1 text-sm text-gray-500">
            XML dosyalarını direkt yükleyebilir veya sıkıştırılmış dosyalar (.zip, .rar, .gz) içindeki XML dosyalarını otomatik olarak işleyebilirsiniz.
          </p>
        </div>

        <FileUpload 
          onFileChange={onFileChange}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />

        {message && (
          <div className="mt-4 flex items-start space-x-2 text-sm text-blue-600">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <p>{message}</p>
          </div>
        )}
      </div>

      {Object.keys(fileProgress).length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900">Yükleme Durumu</h3>
            <div className="text-sm text-gray-500">
              Toplam: {stats.total} | 
              Tamamlanan: <span className="text-green-600">{stats.completed}</span> | 
              Başarısız: <span className="text-red-600">{stats.failed}</span> | 
              Duplicate: <span className="text-yellow-600">{stats.duplicate}</span> | 
              İşleniyor: <span className="text-blue-600">{stats.inProgress}</span>
            </div>
          </div>
          
          <UploadProgress
            files={fileProgress}
            message=""
          />
        </div>
      )}
    </div>
  );
};

export default FileUploadSection;